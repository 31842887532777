/* Carousel.css */
.carousel {
    position: relative;
    width: 100%;
   
    margin: auto;
    overflow: hidden;
  }
  
  .carousel__track {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
  }
  
  .carousel__slide {
    flex: 0 0 auto;
    min-width: 100%;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
  }
  
  .carousel__slide.active {
    opacity: 1;
  }
  
  .carousel__slide.animating {
    transition: none;
  }
  
  .carousel__slide img {
    width: 100%;
    height: auto; /* Ensures images maintain their aspect ratio */
    border-radius: 10px;
  }
  
  .carousel__caption {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .carousel__button {
    position: absolute;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .carousel__button--left {
    left: 10px;
  }
  
  .carousel__button--right {
    right: 10px;
  }
  