.custom-button {
  direction: rtl;
  width: 100%;
  background-color: rgba(252, 195, 24, 0.05); /* Slightly transparent background */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 2px;
  border-color: #3C7BBE;
  cursor: pointer;
  overflow: hidden; 
  position: relative; 
  justify-content: center;
  display: grid;
  /* height: 55px; */
}

.custom-button-Wishlist {
  direction: rtl;
  width: 100%;
  background-color: rgba(252, 195, 24, 0.05); /* Slightly transparent background */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 2px;
  border-color: #3C7BBE;
  cursor: pointer;
  overflow: hidden; 
  position: relative; 
  justify-content: center;
  display: grid;
  height: 55px;
  background-color: #3C7BBE;
}

.custom-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3C7BBE; 
  transform: translateY(-100%);
  transition: transform 0.5s ease;
}


.custom-button:hover::before {
  transform: translateY(0);
}

.custom-button:hover .button-text {
    color: white; 
  }
  .custom-button:hover .icon {
    color: white; 
  }

.button-content {
  padding: 10px;
  position: relative;
  z-index: 1;
  display: grid;
}

.button-text {
  margin: 0;
  font-weight: bold;
  color: #3C7BBE;
  transition: color 0.5s ease;
}


.icon {
  margin: 0;
  font-weight: bold;
  color: #3C7BBE;
  transition: color 0.5s ease;
    height: 25px ;
    margin-top: auto;
    margin-bottom: auto;
}

.icon-wishlist {
  margin: 0;
  font-weight: bold;
  color: #fff;
  transition: color 0.5s ease;
    height: 25px ;
    margin-top: auto;
    margin-bottom: auto;
}
