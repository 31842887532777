.section-header {
    direction: rtl;
    width: 100%;
    background-color: #3C7BBE;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden; /* Ensure the content doesn't overflow */
    position: relative; /* Needed for the pseudo-element */
 
  }
  
  .section-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3C7B88; /* Change to desired hover color */
    transform: translateY(-100%);
    transition: transform 0.5s ease;
  }
  
  .section-header:hover::before {
    transform: translateY(0);
  }
  
  .section-content {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    padding: 10px;
    position: relative; /* Ensure content is above the pseudo-element */
    z-index: 1; /* Ensure content is above the pseudo-element */
  }
  
  .section-title {
    margin: 0;
    font-weight: bold;
    color: #fff

  }
  