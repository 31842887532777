  
  .slick-dots.slick-thumb {
    position: relative;
    display: grid !important;
    grid-template-columns: repeat(5, 60px); 
    gap: 5px;
    /* overflow-y: auto;  */
    margin-right: auto;
    margin-left:auto;
    padding: 10px; 
    border:'1px solid #ccc';
  }
  
  .slick-dots.slick-thumb li {
    display: inline-block;
    margin: 20px;
  }
  
  .slick-dots.slick-thumb li img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    border: 2px solid transparent;
    opacity: 0.5;
    transition: border-color 0.3s ease, opacity 0.3s ease;
  }
  
  .slick-dots.slick-thumb li.slick-active img {
    width:'40px';
    height: '40px';
    border-color: #3C7BBE;
    opacity: 1;
  }
  
  
  
  