.breadcrumb {
    display: flex;
    flex-wrap: wrap; /* Wrap items if they overflow the container */
    background-color: #3C7BBE;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .breadcrumbmobile {
    display: flex;
    flex-wrap: wrap; /* Wrap items if they overflow the container */
    background-color: #3C7BBE;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .breadcrumbmobile a {
    margin-right: 10px; /* Add spacing between breadcrumb items */
    font-weight: bold;
    
  }
  
  .breadcrumbmobile a:last-child {
    margin-right: 10px; /* Remove margin from the last item */
    color: #00000099;  
} 
  .breadcrumb a {
    margin-right: 10px; /* Add spacing between breadcrumb items */
    font-weight: bold;
    
  }
  
  .breadcrumb a:last-child {
    margin-right: 10px; /* Remove margin from the last item */
    color: #00000099;  
}