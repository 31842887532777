/* Default styles */
.slider-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar {
  display: flex;
  flex-direction: row;
  max-width: 30%;
  max-height: 400px;
  border-radius: 5px;
  margin: 20px;
}

.slider1 {
  position: relative;
  width: 70%; /* 70% of the container width */
  overflow: hidden;
  margin-right: 20px; /* Spacing between slider and navbar */
}

.slider1:hover .prev-btn{
  opacity: 0.5;
}


.slider1:hover .next-btn{
  opacity: 0.5;
}

.slides-container {
  display: flex;
  transition: transform 0.5s ease;
}

.slide1 {
  flex: 0 0 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide1.active {
  opacity: 1;
}

.slide1 .slide-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #3C7BBE;
  color: white;
  padding: 10px 20px;
  border: none;
  width:60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}



.navbar {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  max-width: 30%;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 5px;
  margin: 20px;
}

.navbar::-webkit-scrollbar {
  width: 8px;
}

.navbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.navbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nav-img {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  cursor: pointer;
  opacity: 0.5; /* Default opacity for unselected images */
}

.nav-img.active {
  border: 2px solid #3C7BBE;
  opacity: 1; /* Opacity for selected image */
}

/* Mobile responsive styles */
@media only screen and (max-width: 600px) {
  .slider-container {
      flex-direction: column-reverse; /* Change flex direction for mobile */
  }

  .navbar {
      display:flex;
      flex-direction: row; /* Change flex direction for mobile */
      max-width: 100%;
      height: 100px;
  }
  .slider1 {
      width: 100%;
      margin-right: 0;
  }
}
