
  .styled-button {
    background-color: #3C7BBE;
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: bold;
  }
  
  .styled-button:hover {
    background-color: #3C7B88;
    color:white;
    transform: scale(1.05);
  }
  
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 70%;
    text-align: center;
    position: relative;
  }
  
  .styled-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin: 10px 0;
  }
  
  .toggle-text {
    color: #007bff;
    cursor: pointer;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .close-button {
    background-color: #fff;
    color: #888;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
    margin-left: auto;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ccc;
  }
  
  .close-button:hover {
    background-color: #ddd;
    transform: scale(1.05);
  }
  