.slider-container {
  margin: auto;
  flex: 2;
}

.details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: rgb(229, 255, 0); */

}

.slick-dots.slick-thumb {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots.slick-thumb li {
  margin: 10px 45px; /* Increase margin here */
}

.slick-dots.slick-thumb li img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  opacity: 0.5;
}

.slick-dots.slick-thumb li.slick-active img {
  border-color: #3C7BBE;
  opacity: 1;
}

  
  