
* {
  font-family: 'Font' !important;
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @font-face {
  font-family: "FrutigerLTArabic45Light";   
  src: local('FrutigerLTArabic45Light.ttf'), url('./assets/fonts/FrutigerLTArabic45Light.ttf') format('truetype');} */

  @font-face {
    font-family: "Font";   
    src: local('./FrutigerLTArabic45Light.ttf'), url('./assets/fonts/FrutigerLTArabic45Light.ttf') format('truetype');}
p{
  margin:0
}
h3{
  margin: 0;
}

.numberinputbutton{
  background-color: #3C7BBE;
  border-color: #3C7BBE;
}
.p-inputtext:enabled:focus{
  border-color: #3C7BBE !important;
  box-shadow: 0 0 0 0.1rem #3C7BBE;
}
.p-inputtext:enabled:hover{
  border-color: #3C7BBE !important;
  box-shadow: 0 0 0 0.1rem #3C7BBE;
}

.diagonal-strikethrough {
  position: relative; /* Needed for pseudo-element positioning */
}

.diagonal-strikethrough:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 50%;
  background-color: #cc0000cc; /* Change color as needed */
  transform: rotate(-30deg); /* Adjust angle for desired slope */
}
